import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import DrivewayGatesImg from '../../../assets/images/driveway-gates.jpg'

class DrivewayGates extends React.Component {
	render() {
		const siteTitle =
			'Dallas & Fort Worth Driveway Gates - Arlington Driveway Gate Installation'
		const siteDescription =
			'Buy driveway gates in Arlington, TX and the entire Dallas-Fort Worth, TX Area. Call us today to learn more about our driveway gate services.'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">Driveway Gates</h1>
						<hr />
					</div>
				</div>

				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-12">
							<p>
								Driveway gates are designed to help protect you from intruders,
								vandals, and burglars. The front of homes and buildings are
								often what catches the eye of dangerous individuals, and having
								a driveway gate right in plain view is a powerful deterrent.
								There are many other benefits to installing driveway gates,
								including:
							</p>
							<ul>
								<li>
									<i className="fas fa-dungeon mr-2" />
									Blocking unknown individuals from getting in without
									authorization.
								</li>
								<li>
									<i className="fas fa-dungeon mr-2" />
									Improving the building aesthetics by incorporating a
									decorative gate and fence into the landscape.
								</li>
								<li>
									<i className="fas fa-dungeon mr-2" />
									Safe containment of children and pets.
								</li>
								<li>
									<i className="fas fa-dungeon mr-2" />
									Greatly increases property value.
								</li>
							</ul>
							<h4>Benefits of Chain Link Gate Systems</h4>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-8">
							<p>
								Construct a structure that fits the size of the vehicle and
								driveway. Our skilled professionals provide the best driveway
								gates in Arlington, Dallas & Fort Worth. We work with you to
								pick the most appealing, secure driveway gate options,
								incorporating excellent design, safety, and performance
								elements.
							</p>
							<p>
								Driveway gates come in different sizes, materials, and looks, so
								be cautious as you make a final decision. Choose whether you
								want to use a manual or automatic gate opener based on
								convenience. We’re used to working with all the different
								materials that comprise gates and fences, including wrought
								iron, steel, and aluminum.
							</p>
						</div>
						<div className="col-md-4 mt-4">
							<LazyLoadImage
								className="img-fluid"
								src={DrivewayGatesImg}
								alt="Chain Link Gate"
							/>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h5>Factors to Consider</h5>
							<ul>
								<li>
									<i className="fas fa-dungeon mr-2" />
									Weather: Our metal gates are strong and long-lasting under the
									worst weather conditions.
								</li>
								<li>
									<i className="fas fa-dungeon mr-2" />
									Performance: Driveway gates must be sturdy enough to handle
									the constant actions of being swung opened and closed.
								</li>
								<li>
									<i className="fas fa-dungeon mr-2" />
									Space: There are swinging or sliding gates for driveways, so
									choose an opening option that allows the unit to open and
									close freely. Size and space are important qualities. You can
									use standard sizes, or add more posts or slats that fit your
									specific needs. Create custom-measured gates for driveways
									that vary based on heights, lengths, and widths. Leave plenty
									of space to avoid hitting people or objects.
								</li>
							</ul>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h4>Get Our Gate Services</h4>
							<p>
								We{' '}
								<Link to="/gates/services">
									install and repair entrance gates
								</Link>{' '}
								in Dallas and Fort Worth, TX for all kinds of properties. Call
								now for more information!{' '}
								<a href="tel:8174662794">(817)-466-2794</a> We have a
								comprehensive inventory of designs, allowing you to pick the
								perfect solution for your property, which we’ll then tailor
								according to your specifications and needs. Contact us for a
								free estimate!
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default DrivewayGates

export const DrivewayGatesPageQuery = graphql`
	query DrivewayGatesPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
